import { ethers } from "ethers";
import rootProxyAbi from "./abis/rootProxyRegistry.json";




let goerli_root_proxy_contract = "0x890288DddE7282A06720D8848878C98CfC7eB694"
let eth_root_proxy_contract = "0x06fcb47f8f8da29650978ad190fe363483a0be85"
// let root_proxy_contract = "0x890288DddE7282A06720D8848878C98CfC7eB694"
// if mainet or testnet // do this 

export const signMessage2 = async (library,account) => {
    if (!library) return;
    try {
      const normal = ethers.utils.getAddress(account);
      console.log(normal)
      const signature = await library.provider.request({
        method: "personal_sign",
        params: ["hello world", account]
      });
      console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
    }
  };

export const register = async (library,proxy) => {
  let network
  let root_proxy_contract
  try{
    // console.log("here checking for network",library["_network"])
    network = library["_network"]
    if (network["chainId"] === 5){
    root_proxy_contract = goerli_root_proxy_contract
    }
    else{
      root_proxy_contract = eth_root_proxy_contract
    }
  }
  catch(error){
    console.log(error)
  }
  /////////////
    if (!library){ console.log("oops"); return false;}
    try {
     let signer = library.getSigner();
     
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
    //   console.log(register_contract)
      let cur_fee = await register_contract.getFee();
      let result = await register_contract.register(proxy,{value: cur_fee});
      console.log("what occured", result);
      let txresult = await result.wait();
      console.log("what occured2", txresult);
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      // console.log(error);
      return error;
    }
    return true;
  };


export const registerWithProofWeb3 = async (library,proxy) => {
  let network
  let root_proxy_contract
  try{
    // console.log("here checking for network",library["_network"])
    network = library["_network"]
    if (network["chainId"] === 5){
    root_proxy_contract = goerli_root_proxy_contract
    }
    else{
      root_proxy_contract = eth_root_proxy_contract
    }
  }
  catch(error){
    console.log(error)
  }
  /////////////
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
   
    const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
  //   console.log(register_contract)
    let result = await register_contract.registerWithProof(proxy);
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);
  //   const signature = await library.provider.request({
  //     method: "personal_sign",
  //     params: ["hello world", account]
  //   });
  //   console.log(signature)
  //   setSignedMessage("hello world");
  //   setSignature(signature);
  } catch (error) {
    // console.log(error);
    return error;
  }
  return true;
};

  export const checkForProxy = async (library,account) => {
    let network
    let root_proxy_contract
    try{
      // console.log("here checking for network",library["_network"])
      network = library["_network"]
      if (network["chainId"] === 5){
      root_proxy_contract = goerli_root_proxy_contract
      }
      else{
        root_proxy_contract = eth_root_proxy_contract
      }
    }
    catch(error){
      console.log(error)
    }
    /////////////
    let result;
    if (!library){ console.log("oops"); return false;}
    try {
      console.log("here2", account, library)
    //  let signer = library.getSigner();
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
    //   console.log(register_contract)
      result = await register_contract.giveProxy(account);
      console.log(result);
      console.log("here3")
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log("check proxy error", error);
      return error;
    }
    if (ethers.constants.AddressZero == result){
      return "";
    }
    return result;
  };


  export const verifyRootProxyWeb3 = async (library,account,root,proxy) => {
    
    // let address = await library.resolveName('alice.eth');

    // console.log("here1",address)
    let network
    let root_proxy_contract
    try{
      // console.log("here checking for network",library["_network"])
      network = library["_network"]
      if (network["chainId"] === 5){
      root_proxy_contract = goerli_root_proxy_contract
      }
      else{
        root_proxy_contract = eth_root_proxy_contract
      }
    }
    catch(error){
      console.log(error)
    }
    /////////////

    let result;
    if (!library){ console.log("oops"); return false;}
    try {
      console.log("here2", account, library)
    //  let signer = library.getSigner();
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
    //   console.log(register_contract)
      console.log("here3")
      result = await register_contract.verify(root,proxy);
      console.log("result",result);
      console.log("here4")
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
      return error;
    }

    return result;
  };
  export const proveGhostWeb3 = async (library,ghost) => {
    let network
    let root_proxy_contract
    try{
      // console.log("here checking for network",library["_network"])
      network = library["_network"]
      if (network["chainId"] === 5){
      root_proxy_contract = goerli_root_proxy_contract
      }
      else{
        root_proxy_contract = eth_root_proxy_contract
      }
    }
    catch(error){
      console.log(error)
    }
    /////////////
      if (!library){ console.log("oops"); return false;}
      try {
       let signer = library.getSigner();
       
        const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
      //   console.log(register_contract)
        let result = await register_contract.proveProxy(ghost);
        console.log("what occured", result);
        let txresult = await result.wait();
        console.log("what occured2", txresult);
      //   const signature = await library.provider.request({
      //     method: "personal_sign",
      //     params: ["hello world", account]
      //   });
      //   console.log(signature)
      //   setSignedMessage("hello world");
      //   setSignature(signature);
      } catch (error) {
        // console.log(error);
        return error;
      }
      return true;
    };

    export const getFee = async (library) => {
      let network
      let root_proxy_contract
      try{
        // console.log("here checking for network",library["_network"])
        network = library["_network"]
        if (network["chainId"] === 5){
        root_proxy_contract = goerli_root_proxy_contract
        }
        else{
          root_proxy_contract = eth_root_proxy_contract
        }
      }
      catch(error){
        console.log(error)
      }
      /////////////
      console.log("here1")
      let result;
      if (!library){ console.log("oops"); return false;}
      try {
        console.log("here2", library)
      //  let signer = library.getSigner();
        const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
      //   console.log(register_contract)
        result = await register_contract.getFee();
        result = result.toString()
        result = ethers.utils.formatEther( result )
        console.log(result);
        console.log("here3")
      //   const signature = await library.provider.request({
      //     method: "personal_sign",
      //     params: ["hello world", account]
      //   });
      //   console.log(signature)
      //   setSignedMessage("hello world");
      //   setSignature(signature);
      } catch (error) {
        console.log(error);
        return "";
      }
      return result;
    };

    export const getAvatar = async (library,account) => {
      console.log("avatar function called")
      let result;
      let avatar_result;
      let avatar;
      if (!library){ console.log("oops avatar no library"); return false;}
      try {
        // console.log("ens0", library.provider, account)
        result = await library.lookupAddress(account);
        // result = await library.lookupAddress("0x96508B857164105CA8c8F1d6a0f119bAde9360A7");
        // avatar_result = await library.getAvatar("0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045");
        // avatar_result = await library.getAvatar(result);
        console.log("avatar result ", avatar_result)
        avatar_result = await library.getAvatar(result);
        
        // let ensName = await library.lookupAddress("0xeDa90C508B950ba7b63227B9B4B56Ba5b9df0313");
        // console.log(ensName)
        // const resolver = ensName ? await library.getResolver(ensName) : null;
        // avatar = resolver ? await resolver.getAvatar() : null;
        // const ENS = "vitalik.eth"
        // const ENS = "brantly.eth"
        // console.log("ens2", await library.getAvatar(ENS));
        // let avatar_result = await resolver.getText("avatar")
        // let fetching = await fetch("https://boredapeyachtclub.com/api/mutants/25458", {headers: {
        //   origin: null
        // }});
        console.log("ens1", result, avatar_result)
      } catch (error) {
        console.log(error);
        return "";
      }
      return avatar_result;
    }; 
    
    export const getEns = async (library,account) => {
      console.log("here1")
      let result;
      let avatar_result;
      let avatar;
      if (!library){ console.log("oops4"); return false;}
      try {
        // console.log("ens0", library.provider, account)
        result = await library.lookupAddress(account);
        let ensName = await library.lookupAddress("0xeDa90C508B950ba7b63227B9B4B56Ba5b9df0313");
        console.log(ensName)
        // const resolver = ensName ? await library.getResolver(ensName) : null;
        // avatar = resolver ? await resolver.getAvatar() : null;
        // const ENS = "vitalik.eth"
        // const ENS = "brantly.eth"
        // console.log("ens2", await library.getAvatar(ENS));
        // let avatar_result = await resolver.getText("avatar")
        // let fetching = await fetch("https://boredapeyachtclub.com/api/mutants/25458", {headers: {
        //   origin: null
        // }});
        console.log("ens1", result)


        

      } catch (error) {
        console.log(error);
        return "";
      }
      return result;
    }; 