export const truncateAddress = (address) => {
  if (!address) return "No Account Connected";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateGhost= (address) => {
  try{
  if (!address) return "No Ghost Registered";
  console.log("what is it?", address);
  const match = address.match(
    /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
}
catch(err){
  console.log(err);
  return "";
}
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
