import { useEffect, useState } from "react";
import {
  VStack,
  Button,
  Text,
  HStack,
  Select,
  Input,
  Flex,
  Spacer,
  Image,
  Box,
  Container,
  Center,
  Link
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { toHex, truncateAddress, truncateGhost } from "./utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";
import "./styles.css";
import {signMessage2,
  register,
  checkForProxy,
  verifyRootProxyWeb3,
  proveGhostWeb3,
  registerWithProofWeb3,
  getFee,
  getEns,
  getAvatar
} from "./Web3.js";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import Spinner from "./spinner"

// https://v1.chakra-ui.com/docs/components/layout/flex
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

export default function Home() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [started, setStarted] = useState(false);
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [regError, setRegError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  const [sequence, setSequence] = useState("verify");
  const [proxy, setProxy] = useState("");
  const [curProxy, setCurProxy] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [verifyProxy, setVerifyProxy] = useState("");
  const [verifyRoot, setVerifyRoot] = useState("");
  const [verifySuccess, setVerifySuccess] = useState("");
  const [verifySuccessColor, setVerifySuccessColor] = useState("green");
  const [verifyError, setVerifyError] = useState("");
  const [proveGhostAddress, setProveGhostAddress] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState("");
  const [proveSuccess, setProveSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [curFee, setCurFee] = useState("");
  const [ensName, setEnsName] = useState("");
  const [avatar, setAvatar] = useState("");
  // const [networkName, setNetworkName] = useState("");
  

  // reload upon account network change
  function refreshPage() {
    window.location.reload(false);
  }

  const fetchAvatarImg = async (imageUrl) => {
    const res = await fetch(imageUrl);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setAvatar(imageObjectURL);
  };


  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      
      setProvider(provider);
      setLibrary(library);

      if (accounts) {setAccount(accounts[0]);
      }
      setChainId(network.chainId);
      console.log("yo setting1",library,accounts[0])
      let curProxy = await checkForProxy(library,accounts[0])
      
      let ens_name = await getEns(library,accounts[0])
      console.log("yo setting ens", ens_name)

      let avatarLink = await getAvatar(library,accounts[0])
      console.log("yo setting ens avatar", avatarLink)
      
      if (ens_name){
        setEnsName(ens_name)
      }
      else{
        setEnsName(null)
      }

      if (avatarLink){
        console.log("fetching avatar link")
        fetchAvatarImg(avatarLink)
      }
      else{
        setAvatar(null)
      }

    } catch (error) {
      setError(error);
    }

  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInputProxy = (e) => {
    const proxy = e.target.value;
    console.log(proxy)
    setProxy(proxy);
    setRegisterSuccess("")
  };

  const handleInputVerifyProxy = (e) => {
    const verifyProxy = e.target.value;
    console.log(verifyProxy)
    setVerifyProxy(verifyProxy);
  };

  const handleInputVerifyRoot = (e) => {
    const verifyRoot = e.target.value;
    console.log(verifyRoot)
    setVerifyRoot(verifyRoot);
  };

  const handleInputProveGhost = (e) => {
    const proveGhost = e.target.value;
    console.log(proveGhost)
    setProveGhostAddress(proveGhost);
    setRegisterSuccess("")
  };

  
  const handleSequence = (e) => {
    const the_id = e.target.id;
    console.log(the_id)
    setSequence(the_id)
    setRegisterSuccess("")
    setProveSuccess("")
    setRegError("")
    setVerifyError("")
    setVerifySuccess("")
    const non_advanced = ["register","verify"]
    if (non_advanced.includes(the_id)){
      setAdvanced(false)
    }
  };

  const handleAdvanced = (e) => {
    console.log("advanced activated")
    setAdvanced(true);
    setSequence("prove")
  };

  

  const registerProxy = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await register(library,proxy)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )

    }
    try{
      let curProxy = await checkForProxy(library,account)
      setCurProxy(curProxy)
      let curFee = await getFee(library)
      setCurFee(curFee)

    }
    catch(err){
      console.log(err)
    }
    if (result === true){
      setRegisterSuccess("Success")
    }
    setLoading(false)

  }


  const registerProxyWithProof = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await registerWithProofWeb3(library,proxy)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )

    }
    try{
      let curProxy = await checkForProxy(library,account)
      setCurProxy(curProxy)
      let curFee = await getFee(library)
      setCurFee(curFee)

    }
    catch(err){
      console.log(err)
    }
    if (result === true){
      setRegisterSuccess("Success")
    }
    setLoading(false)
  }

  const proveGhostFunction = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await proveGhostWeb3(library,proveGhostAddress)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )
    }
    if (result === true){
      setProveSuccess("Success")
    }
    setLoading(false)
  }

  const verifyRootProxy = async (e) =>{
    setLoading(true)
    setVerifyError("")
    setVerifySuccess("")
    let result = await verifyRootProxyWeb3(library,account,verifyRoot,verifyProxy)
    console.log(result)
    if (result === true){
      console.log("good")
      setVerifySuccess("Verified")
      setVerifySuccessColor("green")
    }
    if (result === false){
      console.log("failed")
      setVerifySuccess("Failed to Verify (Root not associated with proxy OR an error occured)")
      setVerifySuccessColor("red")
    }
    if (result.error){
      console.log(result.error.message)
      setVerifyError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setVerifyError("invalid address")
      }
      else(
        setVerifyError(result.message)
      )

    }
    setLoading(false)
  }

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }]
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]]
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      // console.log("here",message)
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = async (accounts) => {
        refreshPage();
        console.log("accountsChanged", accounts);
        if (accounts) {
        setAccount(accounts[0]);
        
      
        }
        // checkForProxy(library,accounts[0])
        console.log("yo",library,accounts[0])
        let curProxy = await checkForProxy(library,accounts[0])
        setCurProxy(curProxy)
        let curFee = await getFee(library)
        setCurFee(curFee)
        let ens_name = await getEns(library,accounts[0])
        console.log("yo setting ens", ens_name)
        if (ens_name){
          setEnsName(ens_name)
        }
        else{
          setEnsName(null)
        }

      };

      const handleChainChanged = async (_hexChainId) => {
        setChainId(parseInt(_hexChainId,16));
        refreshPage();
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);


  useEffect(async () => {
    console.log("whatsup",account,library)
    let curProxy = await checkForProxy(library,account)
    setCurProxy(curProxy)
    let curFee = await getFee(library)
    setCurFee(curFee)
  }, [account]);

  useEffect(() => {
      if (copyMessage == ""){
        return;
      }
      else{
        setTimeout(() => {
          setCopyMessage("")
        }, 2000);
      }
  }, [copyMessage]);



  return (
    <>

<Flex color={"black"} h="20" width={"100%"} p='4' bg='white' borderRadius={"0px 0px 15px 15px"}>
  <Image src='/wallify.png'></Image>
  <Spacer />
  <HStack >
          {chainId === 1 ? (
            <Text>Mainnet</Text>
            ) : (
            null
          )}
          {chainId === 5 ? (
            <Text>Goerli Testnet</Text>
            ) : (
            null
          )}
          {chainId != null && chainId !== 5 && chainId !== 1 ? (
            <Text color={"red"} >WARNING connected to NOT supported chain</Text>
            ) : (
            null
          )}
          {avatar ? (

               <div style={{translate : "24px", }}>
              <Center background={"black"} borderRadius={"30px"}
              w={{ base: "60px",md: "65px"}} h={{ base: "60px",md: "65px"}}>
              <Image margin={"0px"} borderRadius={"30px "} w={{ base: "50px",md: "55px"}} src={avatar} ></Image>
              </Center>
              </div>
 
            ):
              (null)
          }
          {!account  ? (
            <Button style={{zIndex : "1"}} background={"black"} color={"white"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
          null
          )}
          {account && !ensName ? (
            <CopyToClipboard text={account}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
        <Box    cursor={"grab"} borderRadius={"10px"} padding={"13px"} paddingLeft={"17px"} 
        background={"black"} color={"white"} >
        {`${truncateAddress(account)}`}
        {ensName}
         </Box>
        </CopyToClipboard>

          ) : (
            null
          )}
          {account && ensName ? (
            <CopyToClipboard text={account}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
        <Box cursor={"grab"} borderRadius={"10px"} padding={"13px"} paddingLeft={"17px"} 
        background={"black"} color={"white"} >
        {ensName}
         </Box>
        </CopyToClipboard>
          ) : (
            null
          )}
        </HStack>

</Flex>


            {/* <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}

          <Tooltip label={account} placement="right">
            <Text>{`Account: ${truncateAddress(account)}`}</Text>
          </Tooltip>
          <Text>{`Network ID: ${chainId ? chainId : "No Network"}`}</Text>
               */}
  {!account || started ? (
    <div >
    <Box  display={{ md: 'flex'}} marginLeft={{md:"auto", base:"20%"}} marginRight={{md:"auto", base:"20%"}} >
    <Spacer/>
    <VStack 
        spacing={-3} marginTop={{md: "180px", base:"90px"}} justifyContent="center" alignItems="center">
          <Text  fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Verify <span style={{ fontWeight: 'bold' }} >NFTs</span></Text>
          <Text   fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Without Ever</Text>
          <Text   fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Holding Them </Text>
          <Button borderWidth='2px' borderColor={"#33ff0f"} transform="translateY(50px)" w={{md:"250px", base:"200px"}} background={"white"}  margin="50px" color={"black"}  onClick={connectWallet}>
          <Text   fontSize="25px" fontFamily={"roboto"}>Get Started</Text>
          </Button>
        </VStack>
        
        <Spacer/>
        <Spacer/>
        <Spacer/>
        
        
    </Box>  
    {/* <Button  transform="translateX(-100px)" w={{md:"250px", base:"200px"}} background={"white"}  margin="50px" color={"black"}  onClick={connectWallet}>Connect Wallet</Button> */}
    
    </div>
          ) : (
      <Box display={{ md: 'flex'}} marginLeft={{md:"auto", base:"25%"}} marginRight={{md:"auto", base:"25%"}} >
        <Spacer/>

        <VStack w="230px" 
        spacing={6} marginTop={{md: "180px", base:"50px"}} justifyContent="center" alignItems="center">
        <Text>  <span>&nbsp;&nbsp;</span> {copyMessage}</Text>
        <HStack >
        <Text> Account </Text>
        <CopyToClipboard text={account}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
        <Box cursor={"grab"} padding={"5px"} borderRadius={20} borderWidth='1px' background={"white"} color={"black"} >
        {account ? (<Text>{`${truncateAddress(account)}`}</Text>) : ""}
        </Box>
        </CopyToClipboard>
        </HStack>
        
        <HStack >
          <Text width={"50px"} > Ghost </Text>
          <CopyToClipboard text={curProxy}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
          <Box cursor={"grab"}  padding={"5px"} borderRadius={20} borderWidth='1px' background={"white"} color={"black"} >
            <Text>{`${truncateGhost(curProxy)}`}</Text>
          </Box>
          </CopyToClipboard>
        </HStack>
        
        <VStack padding={"20px"}  borderWidth='1px' borderRadius={20}>

          <Button id="register" onClick={handleSequence} 
          borderWidth='2px' borderColor={"register" === sequence ? "green" : ""} 
          background={"black"} color={"white"} w="95%">Register Ghost
          </Button>

          <Button id="verify" onClick={handleSequence} 
          borderWidth='2px' borderColor={"verify" === sequence ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Verify
          </Button>
          {(advanced == true)  ? 
          <>
            <Button id="prove" onClick={handleSequence} borderWidth='2px' 
          borderColor={"prove" === sequence ?  "green" : ""}  
          background={"black"} color={"white"} w="95%">Prove Ghost
          </Button>
          <Button id="registerProof" onClick={handleSequence} borderWidth='2px' 
          borderColor={"registerProof" === sequence && advanced ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Reg. With Proof
          </Button>
          </>
          :
          <Button id="advanced" onClick={handleAdvanced} borderWidth='2px' 
          borderColor={"red"}  
          background={"black"} color={"pink"} w="95%">Advanced
          </Button>
          }  

          {/* <Button id="prove" onClick={handleSequence} borderWidth='2px' 
          borderColor={"prove" === sequence ?  "green" : ""}  
          background={"black"} color={"white"} w="95%">Prove Ghost
          </Button>

          <Button id="registerProof" onClick={handleSequence} borderWidth='2px' 
          borderColor={"registerProof" === sequence && advanced ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Reg. With Proof
          </Button> */}

          <Spacer></Spacer>
          <Spacer></Spacer>
          {!account ? (
            <Button background={"black"} color={"white"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <Button marginTop={"200px"} borderWidth='1px' background={"white"} color={"black"} w="75%"
            onClick={disconnect} >Logout</Button>
            
          )}

          </VStack>
        </VStack>
        <Spacer/>
        <Spacer/>
      <Spacer/>
      {"register" === sequence ? 
      <VStack h="300px" w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
        
        <Button onClick={registerProxy} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Register Ghost
          </Button>
          <Text w={{md: "500px", base:"200px"}}>
            Root: {account}
          </Text>
          <Text w={{md: "500px", base:"200px"}}>
           Fee: {curFee} ETH
          </Text>
        
        <VStack padding={"20px"} 
        borderWidth='1px' borderRadius={20}
        >
        <Input
                  placeholder="Enter address"
                  
                  onChange={handleInputProxy}
                  w={{base: "auto", md:"400px"}}
                />
          </VStack>
          {loading ? <Spinner></Spinner> : null}
          <Text color={"red"}>{regError != "" ? regError : null} </Text>
          <Text color={"green"}>{registerSuccess != "" ? registerSuccess : null} </Text>
        </VStack> : ""}

        {"verify" === sequence ? 
      <VStack h="300px" w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
        
        <Button onClick={verifyRootProxy} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Verify Ghost
          </Button>
        
        <VStack  padding={"20px"} 
        borderWidth='1px' borderRadius={20}
        >
        <HStack>
        <div style={{width: "50px", textAlign:"justify"}}> 
        <Text>Root:</Text>
        </div>
            <Input
                    placeholder="Enter address"
                    onChange={handleInputVerifyRoot}
                    w={{base: "auto", md:"350px"}}
                  />
          </HStack>
          <HStack>
            <div style={{width: "50px", textAlign:"justify"}}> 
              <Text>Ghost:</Text>
            </div>
            <Input
                    placeholder="Enter address"
                    
                    onChange={handleInputVerifyProxy}
                    w={{base: "auto", md:"350px"}}
                  />
          </HStack>
          </VStack>
          <div style={{height: "100px"}}>
            {loading ? <Spinner></Spinner> : null}
            <Text color={"red"}>{verifyError != "" ? verifyError : null} </Text>
            <Text color={verifySuccessColor}>{verifySuccess != "" ? verifySuccess : null} </Text>
          </div>
        </VStack> : ""}

        {"prove" === sequence ? 
      <VStack h="300px" w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
                {/* <Text>Use this feature to switch a Ghost Wallet you own to a new root</Text>
        <Text>1. Connect to the dApp with the desired Ghost Wallet</Text>
        <Text>2. Enter below the desired ROOT/PARENT</Text>
        <Text>1. Press the Prove Ghost button above</Text> */}
        
        <Button onClick={proveGhostFunction} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Prove Ghost
          </Button>

        <VStack  padding={"20px"} 
        borderWidth='1px' borderRadius={20}
        >
        <HStack>
        <div style={{width: "50px", textAlign:"justify"}}> 
        <Text>ROOT: </Text>
        </div>
            <Input
                    placeholder="Enter address"
                    onChange={handleInputProveGhost}
                    w={{base: "auto", md:"350px"}}
                  />
          </HStack>
          </VStack>
          <div style={{height: "100px"}}>
          {loading ? <Spinner></Spinner> : null}
          <Text color={"red"}>{regError != "" ? regError : null} </Text>
          <Text color={"green"}>{proveSuccess != "" ? proveSuccess : null} </Text>
          </div>
        </VStack> : ""}

        {"registerProof" === sequence ? 
      <VStack h="300px" w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
        
        <Button onClick={registerProxyWithProof} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Register Ghost With Proof
          </Button>
          <Text w={{md: "500px", base:"200px"}}>
            Root: {account}
          </Text>
        
        <VStack padding={"20px"} 
        borderWidth='1px' borderRadius={20}
        >
        <Input
                  placeholder="Enter address"
                  
                  onChange={handleInputProxy}
                  w={{base: "auto", md:"400px"}}
                />
          </VStack>
          {loading ? <Spinner></Spinner> : null}
          <Text color={"red"}>{regError != "" ? regError : null} </Text>
          <Text color={"green"}>{registerSuccess != "" ? registerSuccess : null} </Text>
        </VStack> : ""}

     
</Box>            
          )}         
{/* <Box display={{ md: 'flex'}} marginLeft={{md:"auto", base:"25%"}} marginRight={{md:"auto", base:"25%"}} >
      <Spacer/>

        <VStack w="220px" 
        spacing={6} marginTop={{md: "200px", base:"50px"}} justifyContent="center" alignItems="center">
        <Box borderRadius={20} borderWidth='1px' background={"white"} color={"black"} w="60%">
        <Text>{`${truncateAddress(account)}`}</Text></Box>
        <VStack padding={"20px"}  borderWidth='1px' borderRadius={20}>

          <Button id="register" onClick={handleSequence} 
          borderWidth='2px' borderColor={"register" === sequence ? "green" : ""} 
          background={"black"} color={"white"} w="95%">Register Ghost
          </Button>

          <Button id="verify" onClick={handleSequence} 
          borderWidth='2px' borderColor={"verify" === sequence ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Verify
          </Button>

          <Button id="prove" onClick={handleSequence} borderWidth='2px' 
          borderColor={"prove" === sequence ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Prove Ghost
          </Button>

          <Spacer></Spacer>
          <Spacer></Spacer>
          {!account ? (
            <Button background={"black"} color={"white"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <Button marginTop={"200px"} borderWidth='1px' background={"white"} color={"black"} w="75%"
            onClick={disconnect} >Logout</Button>
            
          )}

          </VStack>
        </VStack>
        <Spacer/>
        <Spacer/>
      <Spacer/>
      {"register" === sequence ? <VStack w="220px" justifyContent="center" 
        spacing={6} marginTop="150px" marginRight={{md: "200px", lg:"300px" }}>
        <Box borderRadius={20} borderWidth='1px' background={"white"} color={"black"} w="60%">
        <Text>Register</Text></Box>
        <VStack padding={"20px"}  borderWidth='1px' borderRadius={20} >
        <Input
                  placeholder="Enter address"
                  
                  onChange={handleInput}
                  w={{base: "auto", md:"400px"}}
                />
          </VStack>
        </VStack> : ""}
</Box> */}
      {/* <VStack w="220px" justifyContent="center" 
        spacing={6} marginTop="150px" marginRight={{md: "200px", lg:"300px" }}>
        <Box borderRadius={20} borderWidth='1px' background={"white"} color={"black"} w="60%">
        <Text>Register</Text></Box>
        <VStack padding={"20px"}  borderWidth='1px' borderRadius={20} >
        <Input
                  placeholder="Enter address"
                  
                  onChange={handleInput}
                  w={{base: "auto", md:"400px"}}
                />
          </VStack>
        </VStack> */}
     

{/* <Box p={4} display={{ md: 'flex' } } borderWidth='1px' >
  <Box flexShrink={0}>
    <Image
      borderRadius='lg'
      width={{ md: 40 }}
      src='https://bit.ly/2jYM25F'
      alt='Woman paying for a purchase'
    />
  </Box>
  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
    <Text
      fontWeight='bold'
      textTransform='uppercase'
      fontSize='sm'
      letterSpacing='wide'
      color='teal.600'
    >
      Marketing
    </Text>
    <Link
      mt={1}
      display='block'
      fontSize='lg'
      lineHeight='normal'
      fontWeight='semibold'
      href='#'
    >
      Finding customers for your new business
    </Link>
    <Text mt={2} color='gray.500'>
      Getting a new business off the ground is a lot of hard work. Here are five
      ideas you can use to find your first customers.
    </Text>
  </Box>
</Box> */}

      {/* <VStack justifyContent="center" alignItems="center" h="100vh">
        <HStack marginBottom="10px">
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
          >
            
          </Text>
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
            sx={{
              background: "linear-gradient(90deg, #1652f0 0%, #b9cbfb 70.35%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            
          </Text>
        </HStack>
        <HStack >
          {!account ? (
            <Button color={"black"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <Button color={"black"} onClick={disconnect}>Disconnect</Button>
          )}
        </HStack>
        <VStack justifyContent="center" alignItems="center" padding="10px 0">
          <HStack>
            <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}
          </HStack>

          <Tooltip label={account} placement="right">
            <Text>{`Account: ${truncateAddress(account)}`}</Text>
          </Tooltip>
          <Text>{`Network ID: ${chainId ? chainId : "No Network"}`}</Text>
        </VStack>
        {account && (
          <HStack justifyContent="flex-start" alignItems="flex-start">
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="10px"
            >
              <VStack>
                <Button  color={"black"} onClick={switchNetwork} isDisabled={!network}>
                  Switch Network
                </Button>
                <Select placeholder="Select network" onChange={handleNetwork}>
                  <option value="3">Ropsten</option>
                  <option value="4">Rinkeby</option>
                  <option value="42">Kovan</option>
                  <option value="1666600000">Harmony</option>
                  <option value="42220">Celo</option>
                </Select>
              </VStack>
            </Box>
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="10px"
            >
              <VStack>
                <Button color={"black"} onClick={signMessage} isDisabled={!message}>
                  Sign Message
                </Button>
                <Input
                  placeholder="Set Message"
                  maxLength={20}
                  onChange={handleInput}
                  w="140px"
                />
                {signature ? (
                  <Tooltip label={signature} placement="bottom">
                    <Text>{`Signature: ${truncateAddress(signature)}`}</Text>
                  </Tooltip>
                ) : null}
              </VStack>
            </Box>
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="10px"
            >
              <VStack>
                <Button color={"black"} onClick={verifyMessage} isDisabled={!signature}>
                  Verify Message
                </Button>
                {verified !== undefined ? (
                  verified === true ? (
                    <VStack>
                      <CheckCircleIcon color="green" />
                      <Text>Signature Verified!</Text>
                    </VStack>
                  ) : (
                    <VStack>
                      <WarningIcon color="red" />
                      <Text>Signature Denied!</Text>
                    </VStack>
                  )
                ) : null}
              </VStack>
            </Box>
          </HStack>
        )}
        <Text>{error ? error.message : null}</Text>
      </VStack> */}
    </>
  );
}
