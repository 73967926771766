import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  // walletlink: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     appName: "Web 3 Modal Demo", // Required
  //     infuraId: "74feea55c3ca47c58b9cc02ad3d01bab" // Required unless you provide a JSON RPC url; see `rpc` below
  //   }
  // },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "74feea55c3ca47c58b9cc02ad3d01bab" // required
    }
  }
};
